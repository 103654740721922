import buttonModel from './buttonModel.vue'
import formModel from './formModel.vue'
import menuModel from './menuModel.vue'
import tableModel from './tableModel.vue'

const comArr = [buttonModel,formModel,menuModel,tableModel];

// 批量组件注册
const install = function (Vue) {
    comArr.forEach((com) => {
        Vue.component(com.name, com);
    });
};

export default install; // 这个方法以后再使用的时候可以被use调用