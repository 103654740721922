<!--

表头参数
  rowKey：       唯一值，默认id
  height：       表格高度       => 默认无 ，固定表头 = 设置table的内容一个高度并添加滚动。
  maxHeight：    最大高度       => 默认无 ，固定表头 = 设置table的内容一个最高高度并添加滚动。
  iSummary:      是否开启计算合计 => 默认false，自动在底部显示合计行。
  getSummaries： 计算合计函数   => iSummary开启的情况下默认计算所有数字合，也可绑定customSummary函数进行自定义计算。（父级使用:customSummary="customSummary"进行绑定）
  iStripe：      是否显示斑马纹 => 默认false(注意：如果使用了rowClass属性，stripe的背景色会更高，需要手动再设置下子级的背景色)
  isBorder：     是否显示边框   => 默认false
  rowClass：     赋值row的class => 默认无、数组类型。 prop是row的字段名，数据等于values其中一个即赋值给row的class属性 eg：[{ prop:'name', values:[123666,123], class:'bgred'}]
  isExpandAll:   是否展开扩展内容，默认不展开。
  isMerge：      是否进行上下行合并。默认false
  divite：       区分上下行合并是是同类数据才能合并（eg：数据为同个员工数据才合并）

  isCheckbox:    多选功能
  clickRowSelectable:    是否启用点击行选中功能
  fixed：        固定列内容     => 默认无、true = left、right
  highlight:     最后点击的row高亮显示
  unSelectable:  禁止单选/多选，根据对象的下标去判断数据对应的数据是否在数组内，满足一个即禁用eg：{ name:[123,3424], name2:['q4'] }


  注意 => 1、当设置width和fixed且有二级表头时部门，除第一个二级表头，其他的表头会出现隐藏情况。
          解决办法1、去除fixed属性。2、修改二级表头的visibility: hidden;样式。


行参数
  isTooltip： 文字一行显示+显示省略号+添加tooltip => 默认false
  emptyValue：数据为空时显示默认值，支持公共和私有默认值
  headerSlot：表头插槽，可定义插槽名和表头，不设置时表头默认显示label字段

  表格类型type
  index：     默认文本显示。
  expandSlot：拓展内容插槽名字，在父级设置插槽（可自定义插槽插槽名字，下标为expandSlot）。
  index：     序号（数据第几条，跟page绑定了，是随着page的累加而累加）
  radio：     单选功能（跟上面的固定的单选功能是一致的）
  参数
  isLineAll：是否动态根据最长内容设置最大宽

operation操作按钮的参数控制
  slotName：自定义操作按钮 => 回传数据有、operaItem操作按钮、row该行数据、powerList按钮权限、roleList角色权限
  label：按钮名称
  emit: 事件回调名字
  icon: 图标（位置在文字前面）
  backIcon: 图标（位置在文字后面）
  type: 按钮状态
  class: 添加class
  style: 添加style
  disabled: 禁用，根据对象的下标去判断数据对应的数据是否在数组内，满足一个即禁用。eg：{ name:[123,3424], name2:['q4'] },
  power： 按钮权限  => 默认无、单个控制eg：'add'、多个控制eg：['addOne','addAll']。(数据类型不限制是数字还是字符串类型，保持唯一值且为转为布尔值为true即可)
  role：  角色权限  => 默认无、单个控制eg：'admin'、多个控制eg：['admin','superAdmin']。(数据类型不限制是数字还是字符串类型，保持唯一值且为转为布尔值为true即可)
  prop: 获取table的指定字段，配合dict展示对应的字典名字
  dict: 获取字典dict文件的指定数组，配合prop展示对应的字典名字
  isUnderline：是否有下划线 => 默认false、布尔类型

-->

<template>
  <div class="tableWrap">
    <el-table
      ref="elTable"
      :key="key"
      :data="tableData"
      :span-method="spanMethod"
      :style="tableInfo.style"
      :height="tableInfo.height"
      :row-key="tableInfo.rowKey"
      :stripe="tableInfo.iStripe"
      :border="tableInfo.isBorder"
      :tree-props="tableInfo.treeProps"
      :max-height="tableInfo.maxHeight"
      :show-summary="tableInfo.iSummary"
      :row-class-name="tableRowClassName"
      :default-expand-all="tableInfo.isExpandAll"
      :highlight-current-row="tableInfo.highlight"
      :header-cell-style="()=>{return {'background-color' : '#f7f7f8','color': '#000000' }}"
      
      @sort-change="sortChange"
      @row-click="currentChange"
      @summary-method="getSummaries"
      @selection-change="selectionChange"
      @select="changeSelect($event, 'one')"
      @select-all="changeSelect($event, 'all')"
    >
      <!-- 无数据时的展示 -->
      <template slot="empty">
        <el-empty :image-size="200"></el-empty>
      </template>

      <!-- 多选 -->
      <el-table-column
        v-if="tableInfo.isCheckbox"
        type="selection"
        width="55"
        fixed="left"
        :selectable="funUnSelectable"
      ></el-table-column>

      <!-- 固定单选（作用不大） -->
      <!-- <el-table-column v-if="tableInfo.isRadio" width="50" fixed="left">
        <template slot-scope="scope">
          <el-radio
            class="onlyRadio"
            v-model="tableForm.selectRadio"
            :disabled="!funUnSelectable(scope.row,'radio')"
            :label="scope.row[tableInfo.rowKey]"
            @change="changeRadio(scope.row)"
          ></el-radio>
        </template>
      </el-table-column> -->

      <!-- 各类数据显示 -->
      <template v-for="(columnItem, index) in tableInfo.columns">
        <!-- 列表单选radio -->
        <el-table-column
          v-if="columnItem.type == 'radio'"
          :key="index"
          :prop="columnItem.prop"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :width="columnItem.width"
          :minWidth="columnItem.minWidth"
        >
          <template slot-scope="scope">
            <el-radio
              class="onlyRadio"
              v-model="tableForm.selectRadio"
              :disabled="!funUnSelectable(scope.row, 'radio')"
              :label="scope.row[tableInfo.rowKey]"
              @change="changeRadio(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>

        <!-- 序号index -->
        <el-table-column
          v-else-if="columnItem.type == 'index'"
          type="index"
          :key="index"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :width="columnItem.width"
          :minWidth="columnItem.minWidth"
          :index="
            (index) => {
              return (pagination.page - 1) * pagination.size + ++index
            }
          "
        >
        </el-table-column>

        <!-- 扩展插槽expandSlot -->
        <el-table-column
          v-else-if="columnItem.type == 'expandSlot'"
          type="expand"
          :key="index"
          :prop="columnItem.prop"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :width="columnItem.width"
          :minWidth="columnItem.minWidth"
        >
          <template slot-scope="scope">
            <slot
              :name="columnItem.expandSlot"
              :row="scope.row"
              :powerList="powerList"
              :roleList="roleList"
            >
            </slot>
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="columnItem.type == 'tag'"
          :key="index"
          :prop="columnItem.prop"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :width="flexWidth(columnItem)"
          :minWidth="columnItem.minWidth"
          :show-overflow-tooltip="columnItem.isTooltip"
          :sortable="columnItem.sortable"
        >
          <template slot-scope="scope">
            <el-tag :type="showLabel(columnItem, scope.row).type">{{ showLabel(columnItem, scope.row).label }}</el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          v-else-if="columnItem.type == 'img' || columnItem.type == 'img'"
          :key="index"
          :prop="columnItem.prop"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :width="flexWidth(columnItem)"
          :minWidth="columnItem.minWidth"
          :show-overflow-tooltip="columnItem.isTooltip"
          :sortable="columnItem.sortable"
        >
          <template slot-scope="scope">
            <template v-if="columnItem.type === 'img' && scope.row[columnItem.prop]">
              <el-image 
                :fit="columnItem.fit"
                :style="columnItem.style"
                :src="scope.row[columnItem.prop]" 
                :preview-src-list="[scope.row[columnItem.prop]]">
              </el-image>
            </template>
            <template v-if="columnItem.type === 'imgs' && scope.row[columnItem.prop] && scope.row[columnItem.prop].length">
              <el-image 
                v-for="(item, index) in scope.row[columnItem.prop]" :key="index"
                :fit="columnItem.fit"
                :style="columnItem.style"
                :src="item.src" 
                :preview-src-list="scope.row[columnItem.prop]">
              </el-image>
            </template>

          </template>
        </el-table-column>

        <!-- 文本text（默认） -->
        <el-table-column
          v-else
          :key="index"
          :prop="columnItem.prop"
          :width="flexWidth(columnItem)"
          :fixed="columnItem.fixed"
          :label="columnItem.label"
          :minWidth="columnItem.minWidth"
          :sortable="columnItem.sortable"
          :show-overflow-tooltip="columnItem.isTooltip"
          :formatter="
            (row, column, value) => {
              return formatter(row, columnItem)
            }
          "
        >
          <template slot="header" slot-scope="scope">
            <!-- 表头插槽 -->
            <slot
              v-if="columnItem.headerSlot"
              :name="columnItem.headerSlot"
              :column="columnItem"
              :powerList="powerList"
              :roleList="roleList"
            >
            </slot>
            <span v-else>{{ scope.column.label }}</span>
          </template>

          <!-- 预留多级表头-->
        </el-table-column>
      </template>

      <!-- 操作按钮（包含一般link按钮、带有浮窗的link按钮） -->
      <el-table-column
        class-name="operaClass"
        :width="tableInfo.operaWidth"
        :label="tableInfo.operaLabel"
        :fixed="tableInfo.operaFixed"
      >
        <template slot-scope="scope">
          <div @click.stop="()=>{}"> <!--'避免点击操作按钮触发点击行事件'-->
            <template v-for="(operaItem, index) in tableInfo.operation">
              <!-- 按钮插槽 -->
              <slot
                v-if="operaItem.slotName"
                :name="operaItem.slotName"
                :operaItem="operaItem"
                :row="scope.row"
                :powerList="powerList"
                :roleList="roleList"
              >
              </slot>
              <!-- 1、单单的link多个按钮 -->
              <template v-else-if="operaItem.children">
                <el-popover
                  :key="index"
                  width="200"
                  trigger="hover"
                  placement="bottom"
                  popper-class="popOparetion"
                >
                  <!-- 浮窗按钮 -->
                  <el-link
                    slot="reference"
                    class="linkClass"
                    :icon="operaItem.icon"
                    :class="operaItem.class"
                    :style="operaItem.style"
                    :type="operaItem.type ? operaItem.type : 'primary'"
                    :underline="operaItem.isUnderline ? operaItem.isUnderline : false"
                  >
                    <!-- 浮窗自定义按钮 -->
                    <slot
                      v-if="operaItem.slotName"
                      :row="scope.row"
                      :roleList="roleList"
                      :operaItem="operaItem"
                      :powerList="powerList"
                      :name="operaItem.slotName"
                    >
                    </slot>
                      <!-- 默认的文字按钮 -->
                    <span v-else>
                      {{ showLabel(operaItem, scope.row).label }}
                      <i v-if="operaItem.backIcon" :class="operaItem.backIcon"></i>
                    </span>
                  </el-link>
                  <!-- 浮窗显示子列表 -->
                  <div v-for="(chilItem, chilIndex) in operaItem.children" :key="chilIndex">
                    <el-link
                      v-if="iShow(chilItem)"
                      @click.stop="callBack(chilItem, scope.row)"
                      class="popBox"
                      :icon="chilItem.icon"
                      :class="chilItem.class"
                      :style="chilItem.style"
                      :disabled="isDisabled(chilItem, scope.row)"
                      :type="chilItem.type ? chilItem.type : 'primary'"
                      :underline="chilItem.isUnderline ? chilItem.isUnderline : false"
                    >
                      <!-- 浮窗子列表自定义按钮 -->
                      <slot
                        v-if="chilItem.slotName"
                        :row="scope.row"
                        :chilItem="chilItem"
                        :roleList="roleList"
                        :powerList="powerList"
                        :name="chilItem.slotName"
                      >
                      </slot>
                      <!-- 默认的子列表文字按钮 -->
                      <span v-else>
                        {{ showLabel(chilItem, scope.row).label }}
                        <i v-if="chilItem.backIcon" :class="chilItem.backIcon"></i>
                      </span>
                    </el-link>
                  </div>
                </el-popover>
              </template>

              <!-- 2、单单的link文字按钮 -->
              <el-link
                :key="index"
                v-else-if="iShow(operaItem)"
                @click.stop="callBack(operaItem, scope.row)"
                class="linkClass"
                :class="operaItem.class"
                :style="operaItem.style"
                :type="operaItem.type ? operaItem.type : 'primary'"
                :icon="operaItem.icon"
                :disabled="isDisabled(operaItem, scope.row)"
                :underline="operaItem.isUnderline ? operaItem.isUnderline : false"
              >
                <!-- 自定义按钮 -->
                <slot
                  v-if="operaItem.slotName"
                  :name="operaItem.slotName"
                  :operaItem="operaItem"
                  :row="scope.row"
                  :powerList="powerList"
                  :roleList="roleList"
                >
                </slot>
                <!-- 默认的文字按钮 -->
                <span v-else>
                  {{ showLabel(operaItem, scope.row).label }}
                  <i v-if="operaItem.backIcon" :class="operaItem.backIcon"></i>
                </span>
              </el-link>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div :class="'flexPage ' + 'flex' + pagination.position + ' ' + pagination.className">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :small="pagination.iSmall"
        :total="pagination.total"
        :layout="pagination.layout"
        :page-size="pagination.size"
        :page-sizes="pagination.sizes"
        :disabled="pagination.disabled"
        :pager-count="pagination.count"
        :current-page="pagination.page"
        :prev-text="pagination.prevText"
        :next-text="pagination.nextText"
        :background="pagination.background"
        :hide-on-single-page="pagination.hideSinglePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<!--
排序问题  目前只支持单个的前端排序/接口排序

多级表头
 -->
<script>
export default {
  name: 'gui-table',
  props: {
    // 表格参数
    tableSetting: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return []
      },
    },
    // 自定义计算合计函数
    customSummary: {
      type: Function,
      default: null,
    },
    //上下合并上参数
    mergeArr: {
      type: Array,
      default: () => {
        return []
      },
    },
    //自定义合并上下行函数
    customSpan: {
      type: Function,
      default: null,
    },
    //分页默认参数
    pageSetting: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 字典
    dicts: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 授权控制是否显示
    powerList: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 角色控制是否显示
    roleList: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      that: this,
      key: Math.random(), //key一改变则组件重新渲染
      isUpKey: true, // 表格参数改变时，是否重新渲染表格
      tableDefault: {
        // table默认设置
        rowKey: 'id',
        stripe: false,
        border: false,
        columns: [],
        rowClass: [],
        iSummary: false,
        highlight: false,
        treeProps: { children: 'children', hasChildren: 'hasChildren' },
        emptyValue: '',
        selectable: false,
        isExpandAll: false,
        unSelectable: {},

        // 操作按钮默认设置
        operaLabel:'操作',
        operaFixed:'right',

      },
      pageDefault: {
        // page默认设置
        page: 1,
        size: 10,
        total: 0,
        count: 5, //（注意count是5到21的奇数）
        sizes: [10, 20, 50, 100],
        iSmall: false, // 是否为小型分页
        layout: 'total, sizes, prev, pager, next, jumper',
        prevText: '', //上一页按钮文字
        nextText: '', //下一页按钮文字
        disabled: false,
        position: 'right', //分页位置 left偏左(默认)、center居中、right偏右
        className: '', //自定义class
        background: true, //有背景格式
        hideSinglePage: false, //一页时是否隐藏
      },
      tableForm: {
        //table表单
        selectRadio: null,
      },
    }
  },
  computed: {
    tableInfo() {
      console.log('更新tbale完成')
      //解决更新设置table闪动问题，更新key重新渲染组件,且首次不需要重新渲染，避免会微微闪动。
      // （同理也可使用this.$nextTick(() => { this.$refs['table'].doLayout(); })）
      this.isUpKey ? (this.key = Math.random()) : (this.isUpKey = true)
      return Object.assign(this.tableDefault, this.tableSetting)
    },
    pagination() {
      console.log('更新page完成')
      return Object.assign(this.pageDefault, this.pageSetting)
    },
    getDictContent() {
      return (row, { prop, dictCode, dictList }) => {
        if (dictCode) {
          return getDictText(dictCode, row[prop])
        } else if (dictList && dictList.length) {
          return dictList.find((item) => item.value == row[prop]).label
        } else {
          return row[prop]
        }
      }
    },

    // 字典字段过滤
    showLabel() {
      return function (operaItem, row) {
        if (!operaItem.dict) return operaItem
        let arr = dicts[operaItem.dict]
        if (!arr || (arr && !arr.length)) {
          console.warn(`dict文件未添加对应的字典，请先去dict文件添加字典“${operaItem.dict}”！`)
          return operaItem
        }
        let obj = arr.find((i) => i.value == row[operaItem.prop])
        if (obj) {
          return obj
        } else {
          console.warn(`dict文件字典“${operaItem.dict}”对象未添加跟“${row[operaItem.prop]}”对应的值，请先去dict文件添加对应value和label值！`)
        }
        return operaItem
      }
    },
    

  },
  created() {
    this.init()
  },
  methods: {
    init() {},

    // 切换分页数量
    handleSizeChange(val) {
      this.$emit('callBack', {
        emit: 'changePage',
        pages: { ...this.pageSetting,page: 1, size: val },
      })
    },
    // 切换分页
    handleCurrentChange(val) {
      this.$emit('callBack', { 
        emit: 'changePage', 
        pages: { ...this.pageSetting,page: val } 
      })
    },

    // 根据文字动态设置最大宽度
    flexWidth(item) {
      let dataList = this.tableData
      // 已经优先使用设置好的宽度
      if (item.width) return item.width;
      // 无数据则不设置
      if (dataList.length === 0) return;
      // 未设置一行显示则设置宽度
      if (!item.isLineAll) return;
      let {prop = '',label='',widthExt = 0} = item
      let contentWdith = 0;
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      context.font = "14px Microsoft YaHei";
      // 没取值则不设置宽度
      if (!prop && label) return;
      // 计算最大宽
      for (let i = 0; i < dataList.length; i++) {
        const value = dataList[i][prop] + '';
        const valueWidth = context.measureText(value).width
        if (valueWidth > contentWdith) {
          contentWdith = valueWidth
        }
      }
      // 无数据时则不设置宽度
      if(contentWdith == 0) return;
      //最大宽小于标题是则不设置宽度
      const titleWidth = context.measureText(label).width
      if(titleWidth > contentWdith) return;
      return contentWdith + 40 + widthExt + 'px';//widthExt额外宽度
    },

    // 上下合并行功能
    spanMethod({ row, column, rowIndex, columnIndex }) {
      // 自定义合并规则
      if (this.customSpan) {
        return this.customSpan({ row, column, rowIndex, columnIndex })
      }
      // 未启用合并/无合并参数等都是默认不合并
      if (!this.tableInfo.isMerge || !this.mergeArr.length) return
      // 操作按钮不合并
      let fieldObjct = this.tableInfo.columns
      if (
        this.mergeArr[columnIndex] === undefined ||
        (fieldObjct[columnIndex] && fieldObjct[columnIndex].type == 'selection')
      )
        return
      // 上下行合并判断
      const _row = this.mergeArr[columnIndex][rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    },

    // 合计行
    getSummaries(param) {
      // 自定义合计规则
      if (this.customSummary) {
        return this.customSummary(param)
      }
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = '-'
        }
      })
      return sums
    },

    //数据为空是显示默认值，支持公共和私有默认值
    formatter(row, column) {
      // 为空时有默认值显示默认值
      if (row[column.prop] === '' || row[column.prop] === undefined || row[column.prop] === null) {
        // 私有默认值（优先显示）
        if (column.emptyValue) {
          return column.emptyValue
        }
        // 公共默认值
        if (this.tableInfo.emptyValue) {
          return this.tableInfo.emptyValue
        }
      }
      // 有值显示对应的值
      return row[column.prop]
    },

    // 关闭/回显多选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.elTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.elTable.clearSelection()
      }
    },

    // 多选（需要手动选择多选框，点击row时不会触发，所以暂时搁置，使用selectionChange去触发）
    changeSelect(row, type) {
      // this.$emit('callBack', { emit: 'changeSelect',row,type})
    },
    // 多选数据的改变了
    selectionChange(row) {
      this.$emit('callBack', { emit: 'changeSelect', row })
    },

    // 单选
    changeRadio(row) {
      this.$emit('callBack', { emit: 'changeRadio', row })
    },

    // 点击行的处理。判读可选且启用了选择功能
    currentChange(row) {
      if (this.funUnSelectable(row) && this.tableInfo.clickRowSelectable) {
        // 选中多选
        if (this.tableInfo.isCheckbox) {
          this.$refs.elTable.toggleRowSelection(row)
        }
        // 选中单选（有头部单选和列表单选，所以不做控制，直接触发）
        this.tableForm.selectRadio = row[this.tableInfo.rowKey]
        this.changeRadio(row)
      }
    },

    // 是否可选择/单选多选按钮。满足禁用条件其中一个即禁用，默认不禁用。(注意这里是radio和checkbox都是true是启用，false是禁用)
    funUnSelectable(row) {
      let arr = Object.keys(this.tableInfo.unSelectable)
      if (!arr.length) {
        return true
      }
      return !arr.some((key) => {
        if (this.tableInfo.unSelectable[key].includes(row[key])) {
          return true
        }
      })
    },

    // 排序 sortable=true则自动排序不用处理，sortable=custom则调用接口排序
    sortChange(sortData) {
      if (sortData.column.sortable == 'custom') {
        this.$emit('callBack', {
          emit: 'chageSortable',
          column: sortData.column,
          order: sortData.order,
          prop: sortData.prop,
        })
      }
    },

    //赋值row的class（eg：设置某行红色以示警告）
    tableRowClassName({ row, rowIndex }) {
      let className = ''
      if (this.tableInfo.rowClass) {
        let obj = this.tableInfo.rowClass.find((item) => item.values.includes(row[item.prop]))
        className = obj ? obj.class : ''
      }
      return className
    },

    // 操作回调
    callBack(operaItem, row) {
      operaItem.emit
        ? this.$emit('callBack', { emit: operaItem.emit, operaItem, row })
        : console.warn('未添加事件名，请使用emit添加事件名！')
    },

    // 判断disabled条件,满足一个即禁用
    isDisabled(operaItem, row) {
      // 未禁用/未设置都是启用
      if (!operaItem.disabled) return false
      // 布尔true直接禁用
      if (operaItem.disabled === true) return true
      // 对象判断是否可禁用
      let arr = Object.keys(operaItem.disabled),
        bool = false
      arr.map((i) => {
        if (operaItem.disabled[i].includes(row[i])) {
          bool = true
        }
      })
      return bool
    },

    iShow(operaItem = {}) {
      if (operaItem.show === false) return false //是否渲染
      let bool = false,
        isRole = false,
        isPower = false
      if (operaItem.role) {
        if (Object.prototype.toString.call(operaItem.role) === '[object Array]') {
          operaItem.role.some((i) => {
            if (this.roleList.includes(i)) {
              isRole = true
              return true
            }
          })
        } else if (this.roleList.includes(operaItem.role)) {
          isRole = true
        }
      }
      if (operaItem.power) {
        if (Object.prototype.toString.call(operaItem.power) === '[object Array]') {
          operaItem.power.some((i) => {
            if (this.powerList.includes(i)) {
              isPower = true
              return true
            }
          })
        } else if (this.powerList.includes(operaItem.power)) {
          isPower = true
        }
      }
      //1未设置权限、2只设置其中一种权限且授权了、3设置了两种权限且两种权限都有
      if (
        (!operaItem.role && !operaItem.power) ||
        (operaItem.role && !operaItem.power && isRole) ||
        (!operaItem.role && operaItem.power && isPower) ||
        (operaItem.role && isRole && operaItem.power && isPower)
      ) {
        bool = true
      }
      return bool
    },
  },
}
</script>
<style lang="scss" scoped>
.tableWrap {
  display: grid;

  /deep/ .operaClass {
    //设置操作按钮为满高度，方便点击
    padding: 0;
    color: #000000;
  }
  /deep/ .linkClass {
    //操作按钮样式
    min-height: 47px;
    margin-right: 10px;
  }
  .onlyRadio {
    /deep/ .el-radio__label {
      //解决单选文字显示问题，不需要显示文字
      font-size: 0;
    }
  }
  /deep/ .el-table__indent {
    //设置箭头样式。解决固定单选且数据有children时，箭头和选择框上下显示
    padding-left: 0 !important;
  }

  // 分页
  .flexPage {
    display: flex;
    padding: 10px 0;
    /deep/ .number,
    /deep/ .btn-prev,
    /deep/ .btn-next {
      background-color: #fff !important;
    }
    /deep/ .active {
      background-color: #409eff !important;
    }
  }
  .flexright {
    justify-content: flex-end;
  }
  .flexcenter {
    justify-content: center;
  }
}
</style>
<style lang="scss">
.popOparetion {
  padding: 0 !important;
  width: auto !important;
  margin-top: -10px !important;
  .popBox {
    padding: 10px 15px;
    display: block;
    text-align: center;
    &:hover {
      background-color: #ebeef5;
    }
  }
}
</style>
