<template>
  <div class="formComponent">
    <!-- {{ form }}<br /> -->
    <!-- {{ selectAllOptions }}<br /> -->
    <!-- {{ formData }}<br /> -->
<!-- {{ getOptions }} -->
    <el-form
      ref="form"
      :model="form"
      v-itemWidth="getAttributes('width')"
      :size="getAttributes('size')"
      :style="getAttributes('style')"
      :inline="getAttributes('inline')"
      :disabled="getAttributes('disabled')"
      :className="getAttributes('className')"
      :label-width="getAttributes('label_width')"
      :status-icon="getAttributes('status_icon')"
      :show-message="getAttributes('show_message')"
      :inline-message="getAttributes('inline_message')"
      :label-position="getAttributes('label_position')"
      :hide-required-asterisk="getAttributes('hide_required_asterisk')"
    >
      <template v-for="(item, index) in formInfo">
        <!-- input输入框 -->
        <el-form-item
          v-if="item.type == 'input'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-input
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :readonly="item.readonly"
            :disabled="item.disabled"
            :clearable="item.clearable"
            :maxlength="item.maxlength"
            :suffix-icon="item.suffix_icon"
            :prefix-icon="item.prefix_icon"
            :show-password="item.show_password"
            :show-word-limit="item.show_word_limit"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请输入${item.label}` : `请输入`"
            @blur="formBlur(item, form[item.prop], form)"
            @focus="formFocus(item, form[item.prop], form)"
            @input="formInput(item, form[item.prop], form)"
            @clear="formClear(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          >
            <template v-if="item.append" slot="append">
              <div v-html="item.append"></div>
            </template>
            <template v-if="item.prepend" slot="prepend">
              <div v-html="item.prepend"></div>
            </template>
          </el-input>
        </el-form-item>
        <!-- number类型，跟input的区别就是多了个number修饰符 -->
        <el-form-item
          v-else-if="item.type == 'number'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-input
            v-model.number="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :readonly="item.readonly"
            :disabled="item.disabled"
            :clearable="item.clearable"
            :maxlength="item.maxlength"
            :suffix-icon="item.suffix_icon"
            :prefix-icon="item.prefix_icon"
            :show-password="item.show_password"
            :show-word-limit="item.show_word_limit"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请输入${item.label}` : `请输入`"
            @blur="formBlur(item, form[item.prop], form)"
            @focus="formFocus(item, form[item.prop], form)"
            @input="formInput(item, form[item.prop], form)"
            @clear="formClear(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          >
            <template v-if="item.append" slot="append">
              <div v-html="item.append"></div>
            </template>
            <template v-if="item.prepend" slot="prepend">
              <div v-html="item.prepend"></div>
            </template>
          </el-input>
        </el-form-item>

        <!-- textarea文本框 -->
        <el-form-item
          v-else-if="item.type == 'textarea'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-input
            type="textarea"
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :autosize="item.autosize"
            :readonly="item.readonly"
            :disabled="item.disabled"
            :clearable="item.clearable"
            :maxlength="item.maxlength"
            :show-word-limit="item.show_word_limit"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请输入${item.label}` : `请输入`"
            @blur="formBlur(item, form[item.prop], form)"
            @focus="formFocus(item, form[item.prop], form)"
            @input="formInput(item, form[item.prop], form)"
            @clear="formClear(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          ></el-input>
        </el-form-item>

        <!-- radio单选 -->
        <el-form-item
          v-else-if="item.type == 'radio'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-radio-group
            class="radioWrap"
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :disabled="item.disabled"
            @input="formInput(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          >
            <!-- 单选按钮样式 -->
            <template v-if="item.radioType == 'radioButton'">
              <el-radio-button
                :key="chilIndex"
                :label="chilItem.value"
                :disabled="chilItem.disabled"
                v-for="(chilItem, chilIndex) in getOptions(item.options)"
                >{{ chilItem.label }}</el-radio-button
              >
            </template>
            <!-- 单选一般样式 -->
            <template v-else>
              <el-radio
                :key="chilIndex"
                :label="chilItem.value"
                :border="item.border"
                :disabled="chilItem.disabled"
                v-for="(chilItem, chilIndex) in getOptions(item.options)"
                >{{ chilItem.label }}</el-radio
              >
            </template>
          </el-radio-group>
        </el-form-item>

        <!-- checkbox多个勾选 -->
        <el-form-item
          v-else-if="item.type == 'checkbox'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <div v-if="item.showCheckAll" style="margin-bottom: 15px">
            <el-checkbox
              v-model="checkAllState[item.prop]"
              :disabled="checkboxDisabled(item)"
              :indeterminate="isIndeterminate(item)"
              @change="checkAllChange($event, item)"
              >全选</el-checkbox
            >
          </div>
          <el-checkbox-group
            v-model="form[item.prop]"
            :ref="item.ref"
            :min="item.min"
            :max="item.max"
            :size="item.size"
            :disabled="item.disabled"
            @change="formChange(item, form[item.prop], form)"
          >
            <!-- 多选按钮样式 -->
            <template v-if="item.radioType == 'checkboxButton'">
              <el-checkbox-button
                :key="chilIndex"
                :label="chilItem.value"
                :disabled="chilItem.disabled"
                v-for="(chilItem, chilIndex) in getOptions(item.options)"
                >{{ chilItem.label }}</el-checkbox-button
              >
            </template>
            <!-- 多选一般样式 -->
            <template v-else>
              <el-checkbox
                :key="chilIndex"
                :label="chilItem.value"
                :border="item.border"
                :disabled="chilItem.disabled"
                v-for="(chilItem, chilIndex) in getOptions(item.options)"
                >{{ chilItem.label }}</el-checkbox
              >
            </template>
          </el-checkbox-group>
        </el-form-item>

        <!-- oneCheckbox单个勾选 -->
        <el-form-item
          v-else-if="item.type == 'oneCheckbox'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-checkbox-group
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :disabled="item.disabled"
            @change="formChange(item, form[item.prop], form)"
          >
            <template>
              <el-checkbox :true-label="item.true_label" :false-label="item.false_label" :border="item.border" :disabled="item.disabled">{{
                item.label_text
              }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </el-form-item>

        <!-- select下拉 -->
        <el-form-item
          v-else-if="item.type == 'select'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-select
            class="selectWrap"
            v-model="form[item.prop]"
            @focus="formFocus(item, form[item.prop], form)"
            :ref="item.ref"
            :size="item.size"
            :disabled="item.disabled"
            :multiple="item.multiple"
            :clearable="item.clearable"
            :filterable="item.filterable"
            :collapse-tags="item.collapse_tags"
            :multiple-limit="item.multiple_limit"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请选择${item.label}` : `请选择`"
          >
            <template>
              <el-option
                v-for="(chilItem, chilIndex) in getOptions(item.options)"
                :key="chilIndex"
                :label="chilItem.label"
                :value="chilItem.value"
                :disabled="chilItem.disabled"
              >
              </el-option>
            </template>
          </el-select>
        </el-form-item>

        <!-- select上传 (修改值时会报错，之后优化，这里用的是另外的值去存储)-->
        <!-- <el-form-item
          v-else-if="item.type == 'upload'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <upload
            :file_list="form[item.prop]"
            :ref="item.ref"
            :tip="item.tip"
            :name="item.name"
            :drag="item.drag"
            :data="item.data"
            :field="item.prop"
            :limit="item.limit"
            :accept="item.accept"
            :action="item.action"
            :maxSize="item.maxSize"
            :headers="item.headers"
            :multiple="item.multiple"
            :disabled="item.disabled"
            :className="item.uploadClass"
            :list_type="item.list_type"
            :isDownload="item.isDownload"
            :show_file_list="item.show_file_list"
            @uploadSuccess="formUploadSuccess"
            @change="uploadChange"
          ></upload>
        </el-form-item> -->

        <!-- picker日期和日期时间选择 -->
        <el-form-item
          v-else-if="item.type == 'picker'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-date-picker
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :align="item.align"
            :type="item.picker_type"
            :readonly="item.readonly"
            :disabled="item.disabled"
            :value-format="item.format"
            :unlink-panels="item.unlink"
            :picker-options="item.options"
            :time-arrow-control="item.arrow"
            :cellClassName="item.cellClassName"
            :range-separator="item.separator ? item.separator : '至'"
            :end-placeholder="item.end_placeholder ? item.end_placeholder : '结束日期'"
            :start-placeholder="item.start_placeholder ? item.start_placeholder : '开始日期'"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请选择${item.label}` : `请选择日期`"
            @blur="formBlur(item, form[item.prop], form)"
            @focus="formFocus(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          >
          </el-date-picker>
        </el-form-item>

        <!-- picker时间选择 -->
        <el-form-item
          v-else-if="item.type == 'time'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <el-time-picker
            v-model="form[item.prop]"
            :ref="item.ref"
            :size="item.size"
            :align="item.align"
            :readonly="item.readonly"
            :disabled="item.disabled"
            :arrow-control="item.arrow"
            :value-format="item.format"
            :picker-options="item.options"
            :range-separator="item.separator ? item.separator : '至'"
            :end-placeholder="item.end_placeholder ? item.end_placeholder : '结束日期'"
            :start-placeholder="item.start_placeholder ? item.start_placeholder : '开始日期'"
            :placeholder="item.placeholder ? item.placeholder : item.label ? `请选择${item.label}` : `请选择日期`"
            @blur="formBlur(item, form[item.prop], form)"
            @focus="formFocus(item, form[item.prop], form)"
            @change="formChange(item, form[item.prop], form)"
          >
          </el-time-picker>
        </el-form-item>

        <!-- slot插槽 -->
        <el-form-item
          v-else-if="item.type == 'slot'"
          v-width="item.width"
          :key="index"
          :prop="item.prop"
          :rules="item.rules"
          :label="item.label"
          :class="item.className"
          :label-width="item.label_width ? item.label_width : getAttributes('label_width')"
        >
          <slot :name="item.slotName" :item="item" :form="form"></slot>
        </el-form-item>
      </template>

      <slot v-if="getAttributes('submitSlot')" :name="getAttributes('submitSlot')" :form="form"></slot>
      <div v-else>
        <el-button @click="onSubmit" type="primary" size="small">确 认</el-button>
        <!-- <el-button @click="resetForm" type="primary" size="small">重 置</el-button> -->
        <el-button @click="$router.back()" size="small">返 回</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
// import upload from '@@/upload.vue'
// import { mapState, mapGetters } from 'vuex'
export default {
  name: 'gui-form',
  components: { 
    // upload 
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    formSetting: {
      type: Object,
      default: () => {
        return {
          formInfo: {},
        }
      },
    },
    // 各种下拉和字典的集合体
    allOptions: {
      type: Object,
      default: () => {
        return {
          formInfo: {},
        }
      },
    },
  },
  data() {
    return {
      form: {}, //表单数据
      formUpload: {}, //直接赋值form的上传文件数据会报错，所以这里另外搞个对象存放
      checkAllState: {}, //全选按钮状态
      selectAllOptions: {}, //select远程搜索的数据存放
      defaultJson: {
        // size: '', //medium、small、mini
        // style:'',
        // inline: false, //行显示
        // disabled: false, //form的disabled优先级大于表单组件的disabled
        // className: '',
        label_width: '100px', //表单组件文字宽度
        // status_icon: false, //是否在输入框中显示校验结果反馈图标
        // show_message:false, //是否显示校验错误信息
        // inline_message: false, //是否以行内形式展示校验信息(display: inline-block;且居中显示)
        // label_position: 'right', //top、left、right
        // hide_required_asterisk: false, //是否隐藏必填字段的标签旁边的红色星号
      },
    }
  },
  directives: {
    itemWidth: {
      bind(el, binding) {
        const nodes = el.getElementsByClassName('el-form-item')
        if (binding.value) {
          for (let index = 0; index < nodes.length; index++) {
            const element = nodes[index]
            element.style.width = binding.value
          }
        }
      },
    },
  },
  computed: {
    // 获取字典信息
    // ...mapGetters(['getOptions']),
    
    getOptions() {
      return function (attr) {
        if (!attr) {
          console.warn('需传入属性名称！')
          return
        }
        return this.allOptions[attr] || []
      }
    },

    formInfo() {
      // 方便后期操作
      let { formInfo } = this.formSetting
      return formInfo ? formInfo : []
    },

    // 设置配置值、无则使用默认值
    getAttributes() {
      return function (attr) {
        if (!attr) {
          console.warn('需传入属性名称！')
          return
        }
        let formValue = this.formSetting[attr]
        let defaultValue = this.defaultJson[attr]
        return formValue !== '' && formValue !== undefined && formValue !== null ? formValue : defaultValue
      }
    },
    // CheckBox显示半选状态。有选且不是全选才显示
    isIndeterminate() {
      return function (item) {
        if (!item.showCheckAll) return
        let arr = this.getOptions(item.options).filter((i) => !i.disabled)
        if (this.form[item.prop] && this.form[item.prop].length && this.form[item.prop].length != arr.length) {
          return true
        }
        return false
      }
    },
    // CheckBox是否禁用全选状态(true不可选，false可用,有得选且是不禁用情况下才能全选)
    checkboxDisabled() {
      return function (item) {
        let arr = this.getOptions(item.options).filter((i) => !i.disabled)
        if (!item.disabled && arr.length) {
          return false
        }
        return true
      }
    },
  },
  watch: {
    // 设置form数据（初始值）、全选数据状态、远程搜索数据存储
    formInfo: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        // 方便后期操作
        if (newVal) {
          for (let index = 0; index < newVal.length; index++) {
            const element = newVal[index]
            // 设置默认值
            let val = ''
            if (this.formData[element.prop]) val = this.formData[element.prop]
            else if (element.type == 'checkbox') {
              val = element.multiple ? [] : ''
              // this.$set(this.selectAllOptions, element.prop, [])
              this.$set(this.checkAllState, element.prop, false)
            } else if (element.type == 'select') {
              val = element.multiple ? [] : ''
            }

            this.$set(this.form, element.prop, val)
          }
        }
      },
    },

    // 设置/更新form数据（父级传过来的数据）
    // formData: {
    //   deep: true,
    //   immediate: true,
    //   handler: function (newVal) {
    //     // 方便后期操作
    //     if (newVal) {
    //       for (const key in this.formData) {
    //         let val = this.formData[key]
    //         if (val) {
    //           this.$set(this.form, key, val)
    //         }
    //       }
    //     }
    //   },
    // },
  },
  created() {
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('成功')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      // 有些数据没有重置为空
      this.$refs.form.resetFields()
    },

    // checkbox的全选和取消全选功能
    checkAllChange(bool, item) {
      let arr = []
      if (bool) {
        arr = this.getOptions(item.options)
          .filter((i) => !i.disabled)
          .map((i) => {
            return i.value
          })
      }
      this.$set(this.form, item.prop, arr)
    },

    // 校验指定字段是否填写合规，eg：参数多个['name','password'], 单个 'name'
    validateField(props) {
      if (Object.prototype.toString.call(props) == '[object Array]') {
        props.map((i) => {
          this.$refs.form.validateField(i)
        })
        return
      }
      this.$refs.form.validateField(props)
    },

    formBlur(item, value, form) {
      if(item.blur){
        this.$emit('formBlur', { item, value, form })
      }
    },
    formFocus(item, value, form) {
      if(item.focus){
        this.$emit('formFocus', { item, value, form })
      }
    },
    formInput(item, value, form) {
      if(item.input){
        this.$emit('formInput', { item, value, form })
      }
    },
    formClear(item, value, form) {
      if(item.clear){
        this.$emit('formClear', { item, value, form })
      }
    },
    formChange(item, value, form) {
      // 改变多选的全选状态
      if (item.showCheckAll && item.type == 'checkbox') {
        let arr = this.getOptions(item.options).filter((i) => !i.disabled)
        let bool = false
        if (this.form[item.prop] && this.form[item.prop].length && this.form[item.prop].length == arr.length) bool = true
        this.$set(this.checkAllState, item.prop, bool)
      }
      if(item.change){
        this.$emit('formChange', { item, value, form })
      }
    },
    // 文件上传回调
    // uploadChange(data) {
    //   console.log(data)
    //   this.$set(this.form, data.field, data.fileList)
    // },
    // formUploadSuccess(data) {
    //   console.log(data)
    // },
  },
}
</script>

<style lang="scss" scoped>
.formComponent {
  
}
.radioWrap {
  .el-radio {
    margin: 0 30px 10px 0;
  }
}
.selectWrap {
  width: 100%;
  /deep/.el-input__inner{
    padding-right: 15px !important;
  }
}
</style>
