<!--
  可控制参数
  class
  style
  type：       类型          => 默认无、primary、success、info、warning、danger、、、
  size：       大小          => 默认最大、medium、small、mini
  icon：       图标          => 默认无、icon名
  plain：      朴素          => 默认false、布尔类型
  round：      圆角          => 默认false、布尔类型
  circle：     圆            => 默认false、布尔类型
  loading：    加载中        => 默认false、布尔类型
  disabled：   禁用          => 默认false、布尔类型
  autofocus：  默认聚焦      => 默认false、布尔类型
  limit：      操作数据条数  => 默认无、操作指定条数eg：1、最少选择n条eg：[n,0]、最多选择m条eg：[0,m]、最少选择n条且最多选择m条eg：[n,m]、。(数据类型需是数字类型)
  power：      按钮权限      => 默认无、单个控制eg：'add'、多个控制eg：['addOne','addAll']。(数据类型不限制是数字还是字符串类型，保持唯一值且为转为布尔值为true即可)
  role：       角色权限      => 默认无、单个控制eg：'admin'、多个控制eg：['admin','superAdmin']。(数据类型不限制是数字还是字符串类型，保持唯一值且为转为布尔值为true即可)
-->

<template>
  <div class="buttonsWrap">
    <template v-for="(item, index) in buttonSetting">
      <!-- 1、连接在一起的按钮 -->
      <el-button-group v-if="item.childrens" :key="index" class="buttonGroup">
        <template v-for="(itemTwo, indexTwo) in item.childrens">
          <el-button
            @click="callBack(itemTwo)"
            v-if="iShow(itemTwo)"
            :key="indexTwo"
            :class="item.class"
            :style="item.style"
            :size="itemTwo.size"
            :icon="itemTwo.icon"
            :type="itemTwo.type"
            :plain="itemTwo.plain"
            :round="itemTwo.round"
            :circle="itemTwo.circle"
            :loading="itemTwo.loading"
            :disabled="itemTwo.disabled"
            :autofocus="itemTwo.autofocus"
          >
            <slot
              v-if="itemTwo.slotName"
              :item="item"
              :itemTwo="itemTwo"
              :name="itemTwo.slotName"
            ></slot>
            <span v-else>{{ itemTwo.name }}</span>
          </el-button>
        </template>
      </el-button-group>
      <!-- 2、单个按钮 -->
      <el-button
        @click="callBack(item)"
        v-else-if="iShow(item)"
        :key="''+index"
        :type="item.type"
        :size="item.size"
        :icon="item.icon"
        :class="item.class"
        :style="item.style"
        :plain="item.plain"
        :round="item.round"
        :circle="item.circle"
        :loading="item.loading"
        :disabled="item.disabled"
        :autofocus="item.autofocus"
      >
        <slot v-if="item.slotName" :name="item.slotName" :item="item"></slot>
        <span v-else>{{ item.name }}</span>
      </el-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'gui-button',
  props: {
    buttonSetting: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectRow: {
      type: Array,
      default: () => {
        return []
      },
    },
    setting: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 授权控制是否显示
    powerList: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 角色控制是否显示
    roleList: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    callBack(item = {}) {
      if (item.emit == 'back') return this.$router.back()
      if (item.limit) {
        let message = ''
        if (Object.prototype.toString.call(item.limit) === '[object Array]') {
          let min = item.limit[0],
            max = item.limit[1]
          if (Number(min) && this.selectRow.length < Number(min)) {
            message = `请至少选择${min}条数据！`
          } else if (Number(max) && this.selectRow.length > Number(max)) {
            message = `最多只能选择${max}条数据！`
          }
        } else {
          if (this.selectRow.length != Number(item.limit)) {
            message = `请选择${item.limit}条数据后再操作！`
          }
        }
        if (message) {
          this.$message({ message, type: 'success' });
          return
        }
      }
      item.emit
        ? this.$emit('callBack', { emit: item.emit, item: item, data: this.selectRow })
        : console.warn('未添加事件名，请使用emit添加事件名！')
    },
    iShow(item = {}) {
      if (item.show === false) return false //是否渲染
      let bool = false,
        isRole = false,
        isPower = false
      if (item.role) {
        if (Object.prototype.toString.call(item.role) === '[object Array]') {
          item.role.some((i) => {
            if (this.roleList.includes(i)) {
              isRole = true
              return true
            }
          })
        } else if (this.roleList.includes(item.role)) {
          isRole = true
        }
      }
      if (item.power) {
        if (Object.prototype.toString.call(item.power) === '[object Array]') {
          item.power.some((i) => {
            if (this.powerList.includes(i)) {
              isPower = true
              return true
            }
          })
        } else if (this.powerList.includes(item.power)) {
          isPower = true
        }
      }
      //1未设置权限、2只设置其中一种权限且授权了、3设置了两种权限且两种权限都有
      if (
        (!item.role && !item.power) ||
        (item.role && !item.power && isRole) ||
        (!item.role && item.power && isPower) ||
        (item.role && isRole && item.power && isPower)
      ) {
        bool = true
      }
      return bool
    },
  },
}
</script>
<style scoped>
/* 解决莫名的间距问题 */
.buttonsWrap {
  display: inline-block;
  /* display: flex;
  flex-wrap: wrap; */
}
/* 限制按钮组不换行 */
.buttonGroup {
  /* margin: 10px 10px 0 0; */
  /* display: flex; */
  display: inline-block;
}
</style>
